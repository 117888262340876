<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            label="일자"
            name="srchDts"
            :range="true"
            v-model="searchParam.srchDts"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="grid"
      title="가동시간"
      tableId="grid"
      :columns="grid.columns"
      :data="grid.data"
      :usePaging="true"
      :columnSetting="false"
      :filtering="true"
      :isExcelDown="false"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable && grid.data.length > 0" label="SEMS 다운로드" icon="download" @btnClicked="downLoadSems"/>
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'air-sems-01',
  data() {
    return {
      editable: true,
      searchParam: {
        plantCd: null,
        srchYear: '',
        srchDts: [],
      },
      grid: {
        columns: [
          {
            name: 'dailyResultDt',
            field: 'dailyResultDt',
            label: '일자',
            align: 'center',
            sortable: false,
          },
          {
            name: 'envAirMstOutletSems',
            field: 'envAirMstOutletSems',
            label: '허가증상배출구번호',
            align: 'center',
            sortable: false,
          },
          {
            name: 'envAirMstOutletName',
            field: 'envAirMstOutletName',
            label: '배출구일련번호',
            align: 'center',
            sortable: false,
          },
          {
            name: 'envAirMstOutletNm',
            field: 'envAirMstOutletNm',
            label: '배출구명',
            align: 'center',
            sortable: false,
          },
          {
            name: 'operationHour',
            field: 'operationHour',
            label: '가동시간ㅣ시간',
            align: 'center',
            sortable: false,
          },
          {
            name: 'operationMin',
            field: 'operationMin',
            label: '가동시간ㅣ분',
            align: 'center',
            sortable: false,
          },
          {
            name: 'remark',
            field: 'remark',
            label: 'LBLREMARK',
            align: 'left',
            sortable: false,
          },
        ],
        data: [],
      },
      listUrl: '',
      saveUrl: '',
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  computed: {
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.listUrl = selectConfig.env.air.sems.oprtimes.list.url;
      this.editable = this.$route.meta.editable;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param =this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    downLoadSems() {
      let thisVue = this;
      this.$http.url = selectConfig.env.air.sems.oprtimes.list.url + '/excel';
      this.$http.param =this.searchParam;
      this.$http.type = 'GET';
      this.$http.request((_result) => {
          var blob = thisVue.$comm.base64ToBlob(_result.data);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, "SEMS가동시간엑셀양식.xlsx");
          } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "SEMS가동시간엑셀양식.xlsx";
            link.click();
          }
      },);
    },
  }
};
</script>
